import {Injectable} from '@angular/core';

import {
    FeatureFlagsService
} from 'src/app/services/api2';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagStorageService {
    public featureFlags: { [key: string]: boolean };
    private doneLoading: Promise<void> | null = null;

    constructor(private featureFlagsService: FeatureFlagsService) {}

    initialize(): Promise<void> {
        if (!this.doneLoading) {
            this.doneLoading = this.featureFlagsService.apiApiFeatureFlagsGet().toPromise()
                .then(flags => {
                    this.featureFlags = flags;
                })
                .catch(error => {
                    console.error('Error fetching feature flags:', error);
                    throw error;
                });
        }
        return this.doneLoading;
    }

    async isFeatureEnabled(featureName: string): Promise<boolean> {
        if (!this.doneLoading) {
            await this.initialize();
        } else {
            await this.doneLoading;
        }
        return !!this.featureFlags[featureName];
    }
}

export function initializeFeatureFlags(featureFlagStorageService: FeatureFlagStorageService) {
    return () => featureFlagStorageService.initialize();
}

